// frontend/src/context/AuthContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../services/api';

interface User {
  id: string;
  username: string;
  role: string;
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  isAdmin: () => boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check if user is already logged in
    const loadUser = async () => {
      const storedUser = localStorage.getItem('user');
      
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }
      
      setLoading(false);
    };

    loadUser();
  }, []);
// In frontend/src/context/AuthContext.tsx
// Inside the login function

const login = async (username: string, password: string) => {
  try {
    const response = await api.post('/auth/login', { username, password });
    const { token, user } = response.data;

    // Debug logging
    console.log('Login successful');
    console.log('Token received:', token ? 'Yes (hidden for security)' : 'No');
    console.log('User data:', user);

    // Store token and user in localStorage
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    
    // Verify token storage
    const storedToken = localStorage.getItem('token');
    console.log('Token stored successfully:', !!storedToken);
    
    setUser(user);
  } catch (error: any) {
    console.error('Login error:', error.response || error);
    throw new Error(error.response?.data?.message || 'Login failed');
  }
};
 

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
    window.location.href = '/';
  };

  const isAdmin = () => user?.role === 'admin';

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        loading,
        login, 
        logout, 
        isAdmin 
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  
  return context;
};