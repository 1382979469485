// frontend/src/context/SocketContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useAuth } from './AuthContext';

// Create the context
const SocketContext = createContext<Socket | null>(null);

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    // Only create socket if user is logged in
    if (!user) {
      console.log("No user logged in, not creating socket");
      return;
    }
    
    // Get authentication token
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("No token found in localStorage");
      return;
    }
    
    // const socketUrl = process.env.REACT_APP_SOCKET_URL || '';
    // console.log(`Connecting to socket at: ${socketUrl}`);
    
    // Get the socket URL with fallbacks
    const socketUrl = window.ENV?.REACT_APP_SOCKET_URL || 
                    process.env.REACT_APP_SOCKET_URL || 
                    'https://quantumcloudtechnologies.com';

    console.log('Using Socket URL:', socketUrl);

    // Create socket instance
    const newSocket = io(socketUrl, {
      path: '/socket.io',
      transports: ['websocket', 'polling'],
      auth: { token },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });
    
    // Set up event listeners
    newSocket.on('connect', () => {
      console.log(`Socket connected with ID: ${newSocket.id}`);
    });
    
    newSocket.on('disconnect', (reason) => {
      console.log(`Socket disconnected. Reason: ${reason}`);
    });
    
    newSocket.on('connect_error', (error) => {
      console.error('Socket connection error:', error.message);
    });
    
    newSocket.on('error', (error) => {
      console.error('Socket error:', error);
    });
    
    // Store socket in state
    setSocket(newSocket);
    
    // Clean up on unmount
    return () => {
      console.log("Cleaning up socket connection");
      newSocket.disconnect();
    };
  }, [user]); // Recreate socket when user changes

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};

 