
// frontend/src/components/Navigation.tsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../styles/Navigation.css';
import { Menu, Close } from '@mui/icons-material';

const Navigation: React.FC = () => {
  const { user, logout } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navigation">
      <div className="nav-container">
        <Link to="/" className="logo">
          QCT
        </Link>

        <div className="menu-icon" onClick={toggleMenu}>
          {menuOpen ? <Close /> : <Menu />}
        </div>

        <ul className={`nav-links ${menuOpen ? 'active' : ''}`}>
          <li>
            <Link to="/" onClick={() => setMenuOpen(false)}>Home</Link>
          </li>
          <li>
            <Link to="/about" onClick={() => setMenuOpen(false)}>About</Link>
          </li>

          {user ? (
            <>
              <li>
                <Link to="/profile" onClick={() => setMenuOpen(false)}>Profile</Link>
              </li>
              <li>
                <Link to="/chat" onClick={() => setMenuOpen(false)}>Chat</Link>
              </li>
              <li>
                <Link to="/files" onClick={() => setMenuOpen(false)}>Files</Link>
              </li>
              {user.role === 'admin' && (
                <>
                  <li>
                    <Link to="/users" onClick={() => setMenuOpen(false)}>Users</Link>
                  </li>
                  <li>
                    <Link to="/roles" onClick={() => setMenuOpen(false)}>Roles</Link>
                  </li>
                </>
              )}
              <li>
                <button 
                  className="logout-btn" 
                  onClick={() => {
                    logout();
                    setMenuOpen(false);
                  }}
                >
                  Logout
                </button>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to="/login" onClick={() => setMenuOpen(false)}>Login</Link>
              </li>
              <li>
                <Link to="/register" onClick={() => setMenuOpen(false)}>Register</Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;