// frontend/src/App.tsx
import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Components
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import LoadingSpinner from './components/LoadingSpinner';
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';

// CSS
import './styles/main.css';

// Context
import { useAuth } from './context/AuthContext';

// Lazy loaded components
const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/About'));
const Login = React.lazy(() => import('./pages/Login'));
const Register = React.lazy(() => import('./pages/Register'));
const Profile = React.lazy(() => import('./pages/Profile'));
const Users = React.lazy(() => import('./pages/Users'));
const AddUser = React.lazy(() => import('./pages/AddUser'));
const Roles = React.lazy(() => import('./pages/Roles'));
const FileManagement = React.lazy(() => import('./pages/FileManagement'));
const Chat = React.lazy(() => import('./pages/Chat'));
const ContactUs = React.lazy(() => import('./pages/ContactUs'));
const NotFound = React.lazy(() => import('./pages/NotFound'));

const App: React.FC = () => {
  const { loading } = useAuth();

  // useEffect(() => {
  //   // Set document title from environment variable
  //   document.title = process.env.REACT_APP_NAME || 'QCT';
  // }, []);
  useEffect(() => {
    // First check runtime ENV, then build ENV, then fallback
    const appName = window.ENV?.REACT_APP_NAME || 
                   process.env.REACT_APP_NAME || 
                   'QCT';
                   
    // Only add the environment indicator in non-production environments
    const appEnv = window.ENV?.REACT_APP_ENV || 
                  process.env.REACT_APP_ENV || 
                  'production';
                  
    // Set document title - only add (Dev) if not in production
    document.title = appEnv !== 'production' ? `${appName} (Dev)` : appName;
    
    console.log('Setting document title:', document.title);
  }, []);
  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Router>
      <div className="App">
        <Navigation />
        <main className="main-content">
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              {/* Public routes */}
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/contact" element={<ContactUs />} />
              
              {/* Protected routes - require authentication */}
              <Route element={<ProtectedRoute />}>
                <Route path="/profile" element={<Profile />} />
                <Route path="/files" element={<FileManagement />} />
                <Route path="/chat" element={<Chat />} />
              </Route>
              
              {/* Admin routes */}
              <Route element={<AdminRoute />}>
                <Route path="/users" element={<Users />} />
                <Route path="/users/add" element={<AddUser />} />
                <Route path="/roles" element={<Roles />} />
              </Route>
              
              {/* 404 route */}
              <Route path="/not-found" element={<NotFound />} />
              <Route path="*" element={<Navigate to="/not-found" replace />} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </div>
      <ToastContainer position="top-right" />
    </Router>
  );
};

export default App;